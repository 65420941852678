/* Estilos para las columnas de patrocinadores */
.sponsor-col {
  display: flex;       /* Añade Flexbox al contenedor */
  align-items: center; /* Centra verticalmente el contenido de la columna */
  justify-content: center; /* Centra horizontalmente el contenido (si es necesario) */
  margin-bottom: 75px; /* Ajusta el margen inferior de cada columna para más espacio entre filas */
  height: 100%;        /* Asegura que la columna tome todo el alto disponible */
}

/* Estilos para los logos de patrocinadores */
.sponsor-logo {
  width: auto;        /* Mantiene la relación de aspecto */
  max-height: 100%;   /* Ajusta el logo para que no exceda el alto de la columna */
  display: block;     /* Asegura que la imagen sea tratada como un bloque */
  margin: auto;       /* Centra horizontalmente la imagen dentro de su columna */
}
