/* RegistrationStyles.css */
.registration-content-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .registration-title {
    margin-top: 40px; /* Ajusta según sea necesario */
    text-align: center;
    margin-bottom: 20px;
  }
  
  .custom-warning {
    width: 80%; /* Ajuste para que coincida con el ancho de la tabla */
    margin: 20px auto; /* Centra horizontalmente y añade margen superior e inferior */
    background-color: #fff3cd; /* Fondo amarillo */
    color: #8a6d3b; /* Texto marrón */
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .table-responsive {
    overflow-x: auto; /* Permite desplazamiento horizontal en pantallas pequeñas */
  }
  
  .price-table {
    width: 80%; /* Ajuste la anchura de la tabla */
    margin: 0 auto; /* Centra la tabla horizontalmente */
    border-collapse: collapse;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Sombra sutil para resaltar la tabla */
  }
  
  .price-table th, .price-table td {
    padding: 10px;
    text-align: center; /* Alineación central para todo el contenido de la tabla */
    border: 1px solid #ddd; /* Líneas más visibles */
  }
  
  .price-table th {
    background-color: #484948; /* Verde intenso para los encabezados */
    color: white;
  }
  
  .button-intro {
    text-align: center; /* Centra el texto */
    margin: 20px 0; /* Añade margen superior e inferior */
  }
  
  .button-group {
    text-align: center; /* Centra los botones */
    margin: 20px 0; /* Espacio antes y después de los botones */
  }
  
  .registration-button {
    color: white;
    margin: 5px 10px; /* Espacio alrededor de cada botón aumentado */
    padding: 10px 20px; /* Padding más generoso para los botones */
  }
  
  .registration-button.participante-completo {
    background-color: #c82333; /* Rojo oscuro */
    border-color: #bd2130;
  }
  
  .text-center {
    text-align: center;
  }

  .important-dates {
    list-style: none; /* Elimina las viñetas */
    padding: 0; /* Elimina el padding predeterminado */
    margin: 0 auto; /* Centra la lista horizontalmente */
    text-align: center; /* Alineación central del texto */
  }

  .important-dates-title {
    font-weight: bold;
    text-align: center;
    margin-top: 20px; 
  }
  