.privacy-policy-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
    line-height: 1.6;
  }
  
  .privacy-policy-container p,
  .privacy-policy-container ul {
    text-align: justify;
    font-size: 1rem; /* Tamaño estándar para el cuerpo del texto */
  }
  
  .privacy-policy-container h1,
  .privacy-policy-container h2,
  .privacy-policy-container h3,
  .privacy-policy-container h4 {
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .privacy-policy-container h1 {
    font-size: 2rem; /* Título principal */
  }
  
  .privacy-policy-container h2 {
    font-size: 1.75rem; /* Subtítulos */
  }
  
  .privacy-policy-container h3 {
    font-size: 1.5rem; /* Sub-secciones */
  }
  
  .privacy-policy-container h4 {
    font-size: 1.25rem; /* Sub-secciones más pequeñas */
  }
  
  .privacy-policy-container ul {
    padding-left: 1.5rem;
  }
  