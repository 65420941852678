/* Contenedor principal que incluye el texto introductorio y el contador */
.countdown-container {
    display: flex;
    flex-direction: column; /* Alinea los elementos en columna */
    align-items: center;  /* Centra los elementos horizontalmente */
    gap: 20px;  /* Espacio entre el texto introductorio y el contador */
  }
  
  /* Texto introductorio sobre el contador */
  .countdown-intro p {
    color: #ffffff;  /* Color blanco para el texto */
    font-size: 1.5rem; /* Tamaño de la fuente grande para resaltar */
    font-weight: bold; /* Negrita para dar más énfasis */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Sombra de texto para mejorar la legibilidad */
    margin-top: 20px; /* Margen superior para separarlo del texto principal */
    margin-bottom: 10px; /* Margen inferior para reducir espacio al contador */
  }
  
  /* Estilos del contenedor del contador */
  .countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Espacio entre las unidades de tiempo */
    color: white; /* Color del texto dentro del contador */
  }
  
  /* Estilos para cada unidad de tiempo dentro del contador */
  .time-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2); /* Fondo semitransparente */
    padding: 10px;
    border-radius: 10px; /* Bordes redondeados para cada unidad */
  }
  
  /* Estilos para los números dentro de cada unidad de tiempo */
  .number {
    font-size: 2rem; /* Tamaño de la fuente grande para los números */
    font-weight: bold; /* Números en negrita para destacar */
  }
  
  /* Estilos para las etiquetas de cada unidad de tiempo */
  .label {
    font-size: 0.75rem; /* Tamaño más pequeño para las etiquetas */
    text-transform: uppercase; /* Texto en mayúsculas para las etiquetas */
  }