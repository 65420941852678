.btn-round {
    width: 200px;  
    height: 50px;  
    padding: 10px 24px; 
    font-size: 12px; 
    display: inline-block; 
    text-align: center; 
    border-radius: 25px; 
    line-height: 25px;
    transition: background-color 0.3s ease; 
  }
  
  .btn-round:hover {
    background-color: #333; 
  }

  .bg-dark-transparent {
    background-color: rgba(0, 0, 0, 0.8); 
    color: #ffffff; 
    padding: 20px; 
    border-radius: 10px; 
}
  